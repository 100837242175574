<template>
    <div class="justify-content-start h-100 view-container">
      <HeaderResidential />
      <div class="mt-4">
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <h1 style="margin: 0;">{{$t('guard.visitsToday')}}</h1>
          <div
            style="display: flex; justify-content: space-between;"
          >
            <div class="pt-1">
              <Button
                :text="$t('guard.exportVisits')"
                color="primary"
                class="d-inline"
                @clicked="exportVisits"
              />
            </div>
            <div>
              <div class="ui icon input" style="width: 99%">
                <input type="text" v-model="filters.text" :placeholder="$t('search')">
                <i class="search icon"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 mb-5" style="margin-left: 12px; margin-right: 13px">
          <div class="row">
            <table class="ui blue unstackable selectable celled table" style="cursor: default !important; color: #808080">
              <thead>
              <tr>
                <th>{{$t('guard.name')}}</th>
                <th style="width: 7%" class="text-center">{{$t('guard.id')}}</th>
                <th style="width: 7%" class="text-center">{{$t('guard.license')}}</th>
                <th style="width: 10%" class="text-center">{{$t('building.apartment')}}</th>
                <th style="width: 10%" class="text-center">{{$t('guard.type')}}</th>
              </tr>
              </thead>
              <tbody v-if="visitsOrdered.length === 0">
                <tr>
                  <td colspan="8" class="text-center">
                    {{$t('guard.noVisits')}}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="visit in visitsOrdered"
                  :key="visit"
                  >
                  <!-- VISITOR FULL NAME -->
                  <td>
                    <h4 class="ui image header">
                      <div class="content">{{ visit.visitorFullName }}</div>
                    </h4>
                  </td>
                  <!-- VISITOR DOCUMENT -->
                  <td class="text-center">
                    <div class="ui image header">
                      <div class="content">{{ visit.visitorDocument }}</div>
                    </div>
                  </td>
                  <!-- VISITOR PLATE -->
                  <td style="text-align: center">
                    <div class="ui image header">
                      <div class="content">{{ visit.vehicleId }}</div>
                    </div>
                  </td>
                  <!-- APARTMENT -->
                  <td class="text-center">
                    <div class="ui image header">
                      <div class="content">{{ visit.apartment }}</div>
                    </div>
                  </td>
                  <!-- VISIT TYPE -->
                  <td class="text-center">
                    <div class="ui image header">
                      <div class="content">{{ getVisitType(visit.type) }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import HeaderResidential from '../../components/ui/HeaderResidential'
import helpers from '../../utils/helpers'
import { getVisitsByResidential } from '../../api/visit'
import Button from '../../components/form/Button'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'Guard',
  components: {
    HeaderResidential,
    Button
  },
  data () {
    return {
      showForm: false,
      visits: [],
      filters: {
        text: ''
      },
      visitTypesNamesMapping: {
        VISIT: this.$t('guard.visit'),
        EMPLOYEE: this.$t('guard.employee')
      },
      excelVisitsReportColumns: [
        this.$t('building.building'),
        this.$t('building.apartment'),
        this.$t('guard.name'),
        this.$t('guard.id'),
        this.$t('guard.license'),
        this.$t('guard.type'),
        this.$t('guard.date')
      ].map(function (colName) {
        return {
          v: colName,
          t: 's',
          s: {
            font: {
              bold: true,
              color: {
                rgb: 'FFFFFF'
              }
            },
            fill: {
              fgColor: {
                rgb: '436F98'
              }
            },
            alignment: {
              vertical: 'center',
              horizontal: 'center'
            },
            border: {
              top: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              bottom: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              left: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              },
              right: {
                style: 'thin',
                color: {
                  rgb: '000000'
                }
              }
            }
          }
        }
      }),
      columnLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G']
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),

    visitsOrdered () {
      let visits = this.visits

      if (this.filters.text) {
        visits = visits.filter(visit => {
          const name = `${visit.visitorFullName.toLowerCase()}`
          const document = `${visit.visitorDocument.toLowerCase()}`
          const plate = `${visit.vehicleId.toLowerCase()}`
          const apartment = `${visit.apartment.toLowerCase()}`
          const type = `${this.getVisitType(visit.type).toLowerCase()}`
          return name.includes(this.filters.text) ||
            document.includes(this.filters.text) ||
            plate.includes(this.filters.text) ||
            apartment.includes(this.filters.text) ||
            type.includes(this.filters.text)
        })
      }

      return visits.sort((a, b) => this.sortByName(a, b))
    }
  },
  created () {
    this.loadVisits()
  },
  methods: {
    async loadVisits () {
      this.$store.dispatch('loading/setLoading', true)
      try {
        const nowDate = new Date()
        const initDate = `${
          nowDate.getFullYear()
        }-${
          `${nowDate.getMonth() + 1}`.padStart(2, '0')
        }-${
          `${nowDate.getDate()}`.padStart(2, '0')
        }`
        const endDate = `${
          nowDate.getFullYear()
        }-${
          `${nowDate.getMonth() + 1}`.padStart(2, '0')
        }-${
          `${nowDate.getDate()}`.padStart(2, '0')
        }`
        const resp = await getVisitsByResidential(
          this.activeResidential.residentialId,
          initDate,
          endDate
        )
        resp.data.forEach(visit => {
          visit.incomingDate = visit.incomingDate.replace('Z', '')
        })
        this.visits = resp.data
      } catch (e) {
        console.log('error getting visits')
        console.log(e)
      }
      this.$store.dispatch('loading/setLoading', false)
    },

    formatDate (date) {
      return helpers.formatDateDDMMYY(date)
    },

    sortByName (a, b) {
      const aName = a.visitorFullName.toLowerCase()
      const bName = b.visitorFullName.toLowerCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    },

    getVisitType (type) {
      return this.visitTypesNamesMapping[type] || this.$t('guard.visit')
    },

    getExcelFormattedStylishVisit (
      visit,
      residentialName
    ) {
      const cellStyleObject = {
        alignment: {
          vertical: 'center',
          horizontal: 'center'
        },
        border: {
          top: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          bottom: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          left: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          },
          right: {
            style: 'thin',
            color: {
              rgb: '000000'
            }
          }
        }
      }
      // console.log('visit.incomingDate', visit.incomingDate)

      return [
        {
          // Building value for row
          v: residentialName,
          s: cellStyleObject
        },
        {
          // Aparment value for row
          v: visit.apartment,
          s: cellStyleObject
        },
        {
          // Visitor's FullName value for row
          v: visit.visitorFullName,
          s: cellStyleObject
        },
        {
          // Visitor's Document value for row
          v: visit.visitorDocument,
          s: cellStyleObject
        },
        {
          // vehicleId name value for row
          v: visit.vehicleId,
          s: cellStyleObject
        },
        {
          // Visit Type value for row
          v: this.getVisitType(visit.type),
          s: cellStyleObject
        },
        {
          // Incoming Date value for row
          v: this.formatDate(new Date(visit.incomingDate)),
          s: cellStyleObject
        }
      ]
    },

    exportVisits () {
      this.$store.dispatch('loading/setLoading', true)

      const residentialName = this.activeResidential.name

      const reservationWorkBook = XLSX.utils.book_new()

      const fileName = `${residentialName}_${this.$t('guard.visits')}_${helpers.formatDateDDMMYY(new Date())}`

      const colsFormatted = this.excelVisitsReportColumns

      const visitsData = [
        colsFormatted,
        ...this.visitsOrdered.map(visit => this.getExcelFormattedStylishVisit(visit, residentialName))
      ]

      const visitsWorkSheet = XLSX.utils.aoa_to_sheet(visitsData)

      const visitsMaxWidth = visitsData.reduce(
        (w, row) => Math.max(
          w, row.reduce(
            (l, objValue) => Math.max(
              l, objValue.v.length
            ),
            10)
        ), 10)

      visitsWorkSheet['!cols'] = this.columnLetters.map(function (colLetter) { return { wch: visitsMaxWidth } })

      XLSX.utils.book_append_sheet(reservationWorkBook, visitsWorkSheet, 'Visitas')

      XLSX.writeFile(reservationWorkBook, `${fileName}.xlsx`)

      this.$store.dispatch('loading/setLoading', false)

      this.$swal(this.$t('guard.visitsExported'), '', 'success')

      // VER ESTO PARA EXPORTAR A CSV DE MANERA GENERALIZADA
      // const visits = this.visitsOrdered.map(visit => {
      //   return {
      //     'Nombre': visit.visitorFullName,
      //     'Documento': visit.visitorDocument,
      //     'Placa': visit.vehicleId,
      //     'Apartamento': visit.apartment,
      //     'Tipo': this.getVisitType(visit.type)
      //   }
      // })
      // helpers.exportToCSV(visits, 'visitas')
    }
  }
}
</script>

<style scoped>
  .ui.table {
    background: transparent;
  }

  .ui.table > thead > tr > th {
    color: #808080
  }

  .ui.image.header > .content {
    font-size: 14px;
    font-weight: normal;
  }

  .nonOnlineRow {
    color: #808080;
  }

  .nonOnlineRow:hover {
    color: #808080 !important;
  }

  .option-muted {
    opacity: .4
  }
</style>
